import React from 'react';
import { useQuery } from 'react-query';
import Loading from '../../Shared/Loading';
import AllScholarshipRow from './AllScholarshipRow';

const AllScholarship = () => {

    const { data: scholarships, isLoading, refetch } = useQuery('scholarships', () => fetch('https://parrots-academy-server-end.onrender.com/scholarships').then(res => res.json()))

    if (isLoading) {
        <Loading></Loading>
    }


    return (
        <div>
            <h1 className='font-semibold text-xl'>Scholarship Application</h1>
            <div className="overflow-x-auto">
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Course</th>
                            <th>Number</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            scholarships?.map((scholarship, index) => <AllScholarshipRow
                                key={scholarship._id}
                                scholarship={scholarship}
                                refetch={refetch}
                                index={index}
                            >
                            </AllScholarshipRow>)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AllScholarship;