import React from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

const Scholarships = () => {

    const { register, formState: { errors }, handleSubmit, reset } = useForm();

    const onSubmit = (data) => {
        const bookAopointment = {
            name: data.name,
            number: data.number,
            purpose: data.course,
        };

        fetch("https://parrots-academy-server-end.onrender.com/addScholarship", {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(bookAopointment),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.insertedId) {
                    console.log(data);
                    reset();
                    Swal.fire({
                        icon: "success",
                        title: "Your request has been recieved. We will get back to you soon.",
                        showConfirmButton: false,
                        timer: 3500
                    });
                }
            });
    }


    return (
        <div>
            <section class="text-gray-600 body-font overflow-hidden">
                <div class="container px-5 py-5 mx-auto">
                    <div className="flex justify-center">
                        <div className="container sm:mt-18 mb-5 my-auto max-w-md rounded-lg">
                            <div className="text-center my-6">
                                <h1 className="text-3xl font-semibold text-gray-700">Scholarship Application</h1>
                                <p className="text-gray-500 py-2">Choose your course and submit your request <br /> We'll confirm your spot soon with a quick call!</p>
                            </div>
                            <div className="m-6">
                                <form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        <label for="name" className="block mb-2 text-sm text-gray-700">Full Name  <span className='text-red-500'>*</span> </label>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Your Full Name"
                                            className="w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                            {...register("name", {
                                                required: {
                                                    value: true,
                                                    message: 'Full name is required'
                                                }
                                            })}
                                        />
                                        <label className="label">
                                            {errors.name?.type === 'required' && <span className="label-text-alt text-red-500">{errors.name.message}</span>}
                                        </label>
                                    </div>
                                    <div className='mt-1'>
                                        <label for="number" className="block mb-2 text-sm text-gray-700">Phone Number <span className='text-red-500'>*</span> </label>
                                        <input
                                            type="text" name="number" placeholder="01XXXXXXXXX"
                                            className="w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                            {...register("number", {
                                                required: {
                                                    value: true,
                                                    message: 'Valid contact number is Required'
                                                },
                                                pattern: {
                                                    value: /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/,
                                                    message: 'Provide a valid contact number'
                                                }
                                            })}
                                        />
                                        <label className="label">
                                            {errors.number?.type === 'required' && <span className="label-text-alt text-red-500">{errors.number.message}</span>}
                                            {errors.number?.type === 'pattern' && <span className="label-text-alt text-red-500">{errors.number.message}</span>}
                                        </label>
                                    </div>
                                    <div className='mt-1 mb-2 ml-5'>
                                        <label for="course" className="text-sm text-gray-700">Preferred Course <span className='text-red-500'>*</span> </label>
                                        <select {...register("course")} name="course" className="select select-ghost w-full max-w-xs" refetch>
                                            <option disabled selected>Pick one</option>
                                            <option>IELTS</option>
                                            <option>SPOKEN ENGLISH</option>
                                            <option>KIDS ENGLISH</option>
                                            <option>KIDS ARABIC</option>
                                        </select>
                                    </div>
                                    <div className="mb-2">
                                        <input
                                            type="submit"
                                            className="w-full px-3 py-4 text-white bg-indigo-500 rounded-md hover:bg-indigo-600 focus:outline-none duration-100 ease-in-out"
                                            value="Submit"
                                        ></input>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Scholarships;
